import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["radio", "submit", "tags"];
  static values = { activeFilter: String, activeTags: String };

  isTagsUnchanged = true;

  connect() {
    this.#updateInitialButtonState();
  }

  changeTag(event) {
    const selectedRadio = this.#getSelectedRadio();

    const isRadioUnchanged = this.#isRadioUnchanged(selectedRadio);
    this.isTagsUnchanged = this.#areTagsUnchanged(event.detail.tagify.value.map(tag => tag.value));

    this.#updateButtonState(isRadioUnchanged, this.isTagsUnchanged);
  }

  changeRadio() {
    const selectedRadio = this.#getSelectedRadio();

    const isRadioUnchanged = this.#isRadioUnchanged(selectedRadio);
    this.#updateButtonState(isRadioUnchanged, this.isTagsUnchanged);
  }

  #updateInitialButtonState() {
    const selectedRadio = this.#getSelectedRadio();

    this.submitTarget.disabled = this.#isRadioUnchanged(selectedRadio);
  }

  #updateButtonState(isRadioUnchanged, isTagsUnchanged = true) {
    this.submitTarget.disabled = isRadioUnchanged && isTagsUnchanged;
  }

  #getSelectedRadio() {
    return this.radioTargets.find(radio => radio.checked);
  }

  #isRadioUnchanged(selectedRadio) {
    return selectedRadio.value === this.activeFilterValue;
  }

  #areTagsUnchanged(currentTags) {
    const activeTags = this.activeTagsValue && this.activeTagsValue.length !== 0 ? JSON.parse(this.activeTagsValue) : [];

    return this.#areArraysEqual(activeTags, currentTags);
  }

  #areArraysEqual(array1, array2) {
    return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
  }
}
