import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

export default class TippyController extends Controller {
  static values = {
    content: String,
    setContent: String,
    contentTimeout: { type: Number, default: 2000 }
  }

  connect () {
    this.timeout = null
    this.tippy = tippy(this.element, this.#options())
  }

  destroy (){
    this.tippy.destroy()
  }

  setContent () {
    if (this.setContentValue) {
      this.tippy.setContent(this.setContentValue)

      // Clear any existing timeout
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(() => {
        this.tippy.setContent(this.contentValue)
      }, this.contentTimeoutValue)
    }
  }

  #options () {
    const delayString = this.element.dataset.delay
    const delayArray = delayString ? delayString.split(',').map(Number) : [0, 0]

    return {
      theme: this.element.dataset.theme === 'light' ? 'light' : 'dark',
      placement: this.element.dataset.placement || 'top',
      arrow: true,
      content: this.contentValue,
      interactive: false,
      allowHTML: true,
      trigger: 'mouseenter',
      delay: delayArray,
      maxWidth: this.element.dataset.maxWidth || 280
    }
  }
}
