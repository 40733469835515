import { Controller } from '@hotwired/stimulus'
import currency from 'currency.js'

export default class Converter extends Controller {
  static targets = ['percentage', 'amount']
  static values = {
    total: { type: String, default: '0' },
    amountDueType: { type: String, default: 'fixed_amount' }
  }

  totalValueChanged () {
    if (this.amountDueTypeValue === 'fixed_amount') {
      this.calculatePercentage()
    }
  }

  setAmountDueType (e) {
    this.amountDueTypeValue = e.target.value
  }

  amountDueTypeValueChanged () {
    if (this.amountDueTypeValue === 'fixed_amount') {
      this.percentageTarget.setAttribute('readonly', 'readonly')
      this.amountTarget.removeAttribute('readonly')
    }

    if (this.amountDueTypeValue === 'percentage_of_total') {
      this.percentageTarget.removeAttribute('readonly')
      this.amountTarget.setAttribute('readonly', 'readonly')
    }
  }

  setTotal (e) {
    this.totalValue = e.detail.totalAmount || '0'

    if (this.amountDueTypeValue === 'percentage_of_total') {
      this.calculateAmount()
    }
  }

  calculatePercentage () {
    const amount = this.amountTarget.value
    const total = this.totalValue

    if (!amount || !this.#isValidNumber(total)) {
      this.percentageTarget.value = ''
      return
    }

    const percentage = currency(amount, { precision: 4 }).divide(total).multiply(100).value

    this.percentageTarget.value = percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(2)
  }

  calculateAmount () {
    const percentage = this.percentageTarget.value
    if (!percentage) {
      this.amountTarget.value = ''
      return
    }

    const total = this.#stringToCurrency(this.totalValue)
    const amount = total.multiply(percentage).divide(100).value
    this.amountTarget.value = this.#stringToCurrency(amount).format()
  }

  #stringToCurrency (value, symbol) {
    return currency(value, { pattern: '#', precision: 0, separator: ',' })
  }

  #isValidNumber (value) {
    const cleanedValue = value.replace(/[.,]/g, '')
    const number = Number(cleanedValue)
    return !isNaN(number) && Number.isFinite(number) && number > 0
  }
}
